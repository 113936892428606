import { Controller } from "stimulus"

export default class extends Controller {
  verifyEmail() {
    if (this.element.querySelector('#enquiry_email').value != this.element.querySelector('#enquiry_email_confirmation').value) {
      event.detail.formSubmission.stop()
      toastr['error']('Your email addresses do not match')
      this.element.querySelector("input[type='submit']").disabled = false
    }
  }

  pushNewsletterSignup() {
    if (event.detail.success && this.element.elements.enquiry_newsletter_opt_in.checked) {
      window.dataLayer = window.dataLayer || []
      dataLayer.push({ 'event': 'newsletterSignup' })
    }
  }
}
