import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "dropdown" ]

  connect() {
    this.holes = []

    this.element.querySelectorAll('tbody tr').forEach(row => {
      const holeInformation = []
      row.querySelectorAll('.field').forEach(field => {
        holeInformation.push(field.textContent)
      })
      this.holes.push(holeInformation)
    })
  }

  updateInformation() {
    let index = 0

    this.element.querySelectorAll('tbody tr .first-field').forEach(row => {
      row.textContent = this.holes[index][this.dropdownTarget.value-1]
      index++
    })
  }
}
