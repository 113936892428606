import { Controller } from "stimulus"

export default class extends Controller {
  updatePreview() {
    const data = event.currentTarget.dataset
    document.querySelector('.hole-indicator.js-active').classList.remove('js-active')
    document.querySelector(`.hole-indicator.hole-${event.currentTarget.dataset.id}`).classList.add('js-active')

    this.featuredImage = document.querySelector('.js-hole-image').src = data.thumbnail
    this.featuredNumber = document.querySelector('.js-hole-number').textContent = data.number
    this.featuredPar = document.querySelector('.js-hole-par').textContent = data.parAndHandicap
  }
}

