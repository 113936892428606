import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.map = this.element.querySelector('.course-map')
    this.resize()
  }

  resize() {
    const scale = (this.element.offsetWidth) / this.map.offsetWidth
    this.map.style.transform = `translate(-50%, -50%) scale(${scale})`
    this.element.style.height = `${this.map.getBoundingClientRect().height}px`
  }
}


// # Save Hole Data to be used for small devices to filter table
// if $('table.data-table')
//   $('table.data-table').find('tbody tr').each (index, row) =>
//     holeInformation = []
//
//     $(row).find('.field').each ->
//       holeInformation.push $(this).text()
//     @holes.push holeInformation



// # Handle table select interactivity - small screen devices
// $('.data-table .custom-select select').change =>
//   table = $('table.data-table')
//   selectValue = $('.data-table .custom-select select').val()
//
//   $('table.data-table').find('tbody tr .first-field').each (index, field) =>
//     $(field).text(@holes[index][selectValue-1])
